import React, { useState, useEffect } from "react";
import axios from "axios";
import { FileWithPath } from "react-dropzone";
import { config } from "../../config/config";
import CareersForm from "../../components/CareersForm";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

type FormData = {
    name: string;
    email: string;
    message: string;
    phone_number: string;
};

const CareersContainer: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [formData, setFormData] = useState<FormData>({
        name: "",
        email: "",
        message: "",
        phone_number: "",
    });

    const [errors, setErrors] = useState<FormData>({
        name: "",
        email: "",
        message: "",
        phone_number: "",
    });

    const [message, setMessage] = useState<string>("");

    const [resumeFile, setResumeFile] = useState<FileWithPath | null>(null);

    const validateEmail = (email: string) => /\S+@\S+\.\S+/.test(email);

    const validatePhone = (phone: string) =>
        /^\+\d{1,3}\s?\d{7,10}$/.test(phone);

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };

    const handleFileDrop = (acceptedFiles: FileWithPath[]) => {
        if (acceptedFiles.length > 0) {
            setResumeFile(acceptedFiles[0]);
        }
    };

    const handleSubmit = async () => {
        let currentErrors = {
            name: "",
            email: "",
            message: "",
            phone_number: "",
        };

        if (!formData.name) currentErrors.name = "Name is required";
        if (!formData.message) currentErrors.message = "Message is required";
        if (!formData.email) currentErrors.email = "Email is required";
        if (!formData.phone_number)
            currentErrors.phone_number = "Phone number is required";

        if (formData.email && !validateEmail(formData.email)) {
            currentErrors.email = "Invalid email format";
        }
        if (formData.phone_number && !validatePhone(formData.phone_number)) {
            currentErrors.phone_number = "Invalid phone number format";
        }

        setErrors(currentErrors);

        const hasErrors = Object.values(currentErrors).some((error) => error);
        if (hasErrors) return;

        const requestData = new FormData();
        requestData.append("name", formData.name);
        requestData.append("email", formData.email);
        requestData.append("message", formData.message);
        requestData.append("phone_number", formData.phone_number);

        if (resumeFile) {
            requestData.append("resume", resumeFile);
        }

        try {
            const response = await axios.post(
                `${config.API.SERVER_API}/auth/careers/`,
                requestData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            setMessage(response.data.message);
        } catch (error: any) {
            console.error("There was an error sending the request", error);
        }
    };

    return (
        <div className="flex justify-center bg-[#152E2C] lg:py-36 py-24">
            <div className="grid grid-cols-2 gap-12 xl:px-24 px-0 max-w-[1700px]">
                <div className="lg:col-span-1 col-span-2 mx-8">
                    <p className="text-[44px] font-extrabold leading-[57px]">
                        Careers
                    </p>
                    <p className="my-10 text-xl leading-8">
                        We are always looking for hungry, creative, and
                        responsible individuals to give us a leg up on the
                        competition.
                    </p>
                    <p className="font-medium text-xl leading-8">
                        Reach out to us using this form.
                    </p>
                </div>
                <CareersForm
                    formData={formData}
                    errors={errors}
                    message={message}
                    resumeFile={resumeFile}
                    handleInputChange={handleInputChange}
                    handleFileDrop={handleFileDrop}
                    handleSubmit={handleSubmit}
                />
            </div>
        </div>
    );
};

export default CareersContainer;
