import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import logo from "../../assets/icons/Vector.png";
import close from "../../assets/icons/delete.png";
import { config } from "../../config/config";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

type FormData = {
    name: string;
    email: string;
    phone_number: string;
    notify: boolean;
    password: string;
};

const RequestInvitation: React.FC<{ onContinue: () => void }> = ({
    onContinue,
}) => {
    const [selectedCountry, setSelectedCountry] = useState("");
    const [formData, setFormData] = useState<FormData>({
        name: "",
        email: "",
        phone_number: "",
        notify: true,
        password: "aaa",
    });
    const [errors, setErrors] = useState<FormData>({
        name: "",
        email: "",
        phone_number: "",
        notify: false,
        password: "",
    });

    const validateEmail = (email: string) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const validatePhone = (phone: string) => {
        return /^\d{10}$/.test(phone); // Validates US phone number format (10 digits)
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        // Reset errors for specific field
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };

    const handleSubmit = async () => {
        let currentErrors = {
            name: "",
            email: "",
            phone_number: "",
            notify: false,
            password: "",
        };

        // Validate empty fields
        if (!formData.name) currentErrors.name = "Name is required";
        if (!formData.email) currentErrors.email = "Email is required";
        if (!formData.phone_number)
            currentErrors.phone_number = "Phone number is required";

        // Validate formats
        if (formData.email && !validateEmail(formData.email)) {
            currentErrors.email = "Invalid email format";
        }
        if (formData.phone_number && !validatePhone(formData.phone_number)) {
            currentErrors.phone_number = "Invalid phone number format";
        }

        setErrors(currentErrors);

        // Check if there are any errors
        const hasErrors = Object.values(currentErrors).some((error) => error);
        if (hasErrors) return;

        try {
            const response = await axios.post(
                `${config.API.SERVER_API}/login/email/`,
                {
                    ...formData,
                }
            );
            onContinue();
        } catch (error: any) {
            console.error("There was an error sending the request", error);
            if (error.response?.status === 422) onContinue();
        }
    };

    return (
        <div className="bg-[#152E2C] flex justify-center items-center py-24">
            <div className="flex flex-col gap-6 w-[356px] relative">
                <Link to="/">
                    <img
                        src={close}
                        alt="delete"
                        className="absolute top-[35px] right-[0px]"
                    />
                </Link>
                <div className="flex flex-col gap-2 items-center">
                    <img
                        src={logo}
                        className="h-[40px] w-12"
                        alt="Applaudable logo"
                    />
                    <p className="text-sm text-white leading-4">Applaudable</p>
                </div>

                <p className="text-white font-bold text-xl leading-8">
                    Request an Invitation
                </p>

                {Object.values(errors).some((error) => error) && (
                    <div className="text-red-500 text-xs mb-2">
                        Please correct the errors before continuing.
                    </div>
                )}

                <div className="flex font-medium flex-col gap-2">
                    <p className="text-white text-xs">Name</p>
                    <input
                        className={`text-white rounded-lg p-4 px-4 border-none focus:outline-none ${
                            errors.name ? "bg-red-300" : "bg-[#FFFFFF0D]"
                        } text-xs`}
                        placeholder="Full Name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                    ></input>
                    {errors.name && (
                        <p className="text-red-500 text-xs">{errors.name}</p>
                    )}
                </div>

                <div className="flex flex-col gap-2">
                    <p className="text-white font-medium text-xs">Email</p>
                    <input
                        className={`text-white rounded-lg p-4 px-4 border-none focus:outline-none ${
                            errors.email ? "bg-red-300" : "bg-[#FFFFFF0D]"
                        } text-xs`}
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                    ></input>
                    {errors.email && (
                        <p className="text-red-500 text-xs">{errors.email}</p>
                    )}
                </div>

                <div className="flex flex-col gap-2">
                    <p className="text-white font-medium text-xs">
                        Phone Number
                    </p>
                    <input
                        className={`text-white rounded-lg p-4 px-4 border-none focus:outline-none ${
                            errors.phone_number
                                ? "bg-red-300"
                                : "bg-[#FFFFFF0D]"
                        } text-xs`}
                        placeholder="Phone Number"
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handleInputChange}
                    ></input>
                    {errors.phone_number && (
                        <p className="text-red-500 text-xs">
                            {errors.phone_number}
                        </p>
                    )}
                </div>

                <button
                    className="rounded-full font-medium bg-[#F72D55] hover:bg-[#e68195] py-2 px-6 leading-6 text-base"
                    onClick={handleSubmit}
                >
                    Continue
                </button>
            </div>
        </div>
    );
};

export default RequestInvitation;
